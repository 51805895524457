<template>
  <div>
    <v-container fluid>
      <v-row v-if="isLoading">
        <v-col cols="12" lg="4" v-for="item in 6" :key="item">
          <v-card class="rounded-lg shadow">
            <v-card-text>
              <v-skeleton-loader
                type="list-item-avatar-three-line"
              ></v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="8" v-for="(item, i) in 1" :key="i">
          <v-card class="rounded-lg shadow">
            <v-card-text>
              <v-skeleton-loader type="image,article"></v-skeleton-loader>
              <v-skeleton-loader
                class="mt-2"
                type="paragraph"
              ></v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="4">
          <v-card class="rounded-lg shadow">
            <v-skeleton-loader
              type="list-item-avatar-two-line"
            ></v-skeleton-loader>
            <v-skeleton-loader
              v-for="(item, i) in 7"
              class="my-1"
              :key="i"
              type="list-item-avatar"
            ></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>

      <div v-else>
        <v-row>
          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-8">
                <v-row justify="center" align="center">
                  <v-col cols="4">
                    <v-avatar color="primary" size="55">
                      <v-icon color="white">mdi-currency-usd</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="8">
                    <div
                      class="font-weight-black title pb-1 grey--text text--darken-3"
                    >
                      {{
                        CurrencyFormatting($store.state.dashboardData.balance)
                      }}
                      DZD
                    </div>
                    Solde Actuel
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-8">
                <v-row justify="center" align="center">
                  <v-col cols="4">
                    <v-avatar color="error" size="55">
                      <v-icon color="white">mdi-currency-usd</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="8">
                    <div
                      class="font-weight-black title pb-1 grey--text text--darken-3"
                    >
                      {{ CurrencyFormatting(dashboardData.total_consumed) }} DZD
                    </div>
                    Total consomation
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-8">
                <v-row justify="center" align="center">
                  <v-col cols="4">
                    <v-avatar color="success" size="55">
                      <v-icon color="white">mdi-database</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="8">
                    <div
                      class="font-weight-black title pb-1 grey--text text--darken-3"
                    >
                      {{ $store.state.dashboardData.total_databases }}
                    </div>
                    Base de données
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-8">
                <v-row justify="center" align="center">
                  <v-col cols="4">
                    <v-avatar color="info" size="55">
                      <v-icon color="white">mdi-sync</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="8">
                    <div
                      class="font-weight-black title pb-1 grey--text text--darken-3"
                    >
                      {{ $store.state.dashboardData.total_transactions }}
                    </div>
                    Nombre des transactions
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text class="pa-8">
                <v-row justify="center" align="center">
                  <v-col cols="4">
                    <v-avatar color="primary" size="55">
                      <v-icon color="white">mdi-currency-usd</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="8">
                    <div
                      class="font-weight-black title pb-1 grey--text text--darken-3"
                    >
                      {{
                        CurrencyFormatting(
                          $store.state.dashboardData.total_transactions_amount
                        )
                      }}
                      DZD
                    </div>
                    Volume des transactions
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- <v-col cols="12" lg="4">
              <v-card class="rounded-lg shadow">
                <v-card-text class="pa-8">
                  <v-row justify="center" align="center">
                    <v-col cols="4">
                      <v-avatar color="gifty" size="55">
                        <v-icon color="white">mdi-account-check</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="8">
                      <div class="font-weight-black title pb-1 grey--text text--darken-3">
                        {{ $store.state.dashboardData.total_users }}
                      </div>
                      Nombre des utilisateurs
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col> -->
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-card class="rounded-lg shadow">
              <v-tabs
                v-model="tab"
                active-class="gifty white--text"
                hide-slider
                centered
              >
                <v-tabs-slider></v-tabs-slider>
                <v-tab href="#tab-1"> Mois</v-tab>
                <v-tab href="#tab-2"> Semaines</v-tab>
                <v-tab href="#tab-3"> Jours</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item value="tab-1">
                  <v-card flat>
                    <v-card-text>
                      <v-card-title> Transactions par mois (CA) </v-card-title>
                      <v-card-text>
                        <v-skeleton-loader
                          type="article"
                          v-if="lastFiveMonthsTransactionsLoading"
                        />
                        <Bar
                          v-else
                          :labels="lastFiveMonthsTransactions.graph.months"
                          :data="lastFiveMonthsTransactions.graph.amounts"
                          title="Transactions par mois (CA)"
                          is_money="true"
                        />
                      </v-card-text>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item value="tab-2">
                  <v-card flat>
                    <v-card-text>
                      <v-card-title>
                        Transactions par semaines (CA)
                      </v-card-title>
                      <v-card-text>
                        <v-skeleton-loader
                          type="article"
                          v-if="lastFiveMonthsTransactionsByWeekLoading"
                        />
                        <Bar
                          v-else
                          :labels="
                            lastFiveMonthsTransactionsByWeek.graphByWeek.labels
                          "
                          :data="
                            lastFiveMonthsTransactionsByWeek.graphByWeek.values
                          "
                          title="Transactions par semaines (CA)"
                          is_money="true"
                        />
                      </v-card-text>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item value="tab-3">
                  <v-card flat>
                    <v-card-text>
                      <v-card-title> Transactions par Jours (CA) </v-card-title>
                      <v-card-text>
                        <v-skeleton-loader
                          type="article"
                          v-if="lastMonthsTransactionsByDayLoading"
                        />
                        <Bar
                          v-else
                          :labels="
                            lastMonthsTransactionsByDay.graphByDays.labels
                          "
                          :data="lastMonthsTransactionsByDay.graphByDays.values"
                          title="Transactions par jours (CA)"
                          is_money="true"
                        />
                      </v-card-text>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-card class="rounded-lg shadow">
              <v-tabs
                  v-model="tab_2"
                  active-class="gifty white--text"
                  hide-slider
                  centered
              >
                <v-tab key="transaction_one">
                  Mois
                </v-tab>
                <v-tab key="transaction_two">
                  Semaines
                </v-tab>
                <v-tab key="transaction_three">
                  Jours
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab_2">
                <v-tab-item key="transaction_one">
                  <v-card flat>
                    <v-card-text>
                      <v-card-title>
                        Transactions par mois (Nombre)
                      </v-card-title>
                      <v-card-text>
                        <v-skeleton-loader type="article" v-if="lastFiveMonthsTransactionsLoading"/>
                        <Bar v-else
                            :labels="lastFiveMonthsTransactions.graphTransactionByQuantity.months"
                            :data="lastFiveMonthsTransactions.graphTransactionByQuantity.counts"
                            title="Transactions par mois (Quantité)"
                        />
                      </v-card-text>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item key="transaction_two">
                  <v-card flat>
                    <v-card-text>
                      <v-card-title>
                        Transactions par semaines (Nombre)
                      </v-card-title>
                      <v-card-text>
                        <v-skeleton-loader type="article" v-if="lastFiveMonthsTransactionsByWeekLoading"/>
                        <Bar v-else
                            :labels="lastFiveMonthsTransactionsByWeek.graphByWeekQuantity.labels"
                            :data="lastFiveMonthsTransactionsByWeek.graphByWeekQuantity.counts"
                            title="Transactions par semaines (Nombre)"
                        />
                      </v-card-text>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item key="transaction_three">
                  <v-card flat>
                    <v-card-text>
                      <v-card-title>
                        Transactions par Jours (Nombre)
                      </v-card-title>
                      <v-card-text>
                        <v-skeleton-loader type="article" v-if="lastMonthsTransactionsByDayLoading"/>
                        <Bar v-else :labels="lastMonthsTransactionsByDay.graphByDaysQuantity.labels"
                             :data="lastMonthsTransactionsByDay.graphByDaysQuantity.counts"
                             title="Transactions par jours (Nombre)"
                        />
                      </v-card-text>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
  
      </div>
    </v-container>
  </div>
</template>
  
  <script>
import Bar from "@/components/charts/Bar.vue";
import { HTTP } from "@/http-common";

export default {
  components: {
    Bar,
  },

  data() {
    return {
      tab: null,
      tab_2: null,
      loading: false,
      labels: [],
      data: [],
      types: ["AG", "SUP", "BT", "Parfumrie"],
      typesData: ["60", "20", "10", "10"],
      ac: ["Actif", "InActif"],
      acdata: ["85", "15"],

      // lastFiveMonthsTransactions
      lastFiveMonthsTransactions: {},
      lastFiveMonthsTransactionsLoading: true,

      //lastFiveMonthsTransactionsByWeekLoading
      lastFiveMonthsTransactionsByWeekLoading: true,
      lastFiveMonthsTransactionsByWeek: {},

      //lastMonthsTransactionsByDay
      lastMonthsTransactionsByDay: {},
      lastMonthsTransactionsByDayLoading: true,
    };
  },

  methods: {
    getLastFiveMonthsTransactions() {
      this.lastFiveMonthsTransactionsLoading = true;
      HTTP.get("dashboard/lastFiveMonthsTransactions")
        .then((res) => {
          this.lastFiveMonthsTransactions = res.data;
          this.lastFiveMonthsTransactionsLoading = false;
        })
        .catch((e) => {
          this.lastFiveMonthsTransactionsLoading = false;
          console.log(e);
        });
    },
    getLastFiveMonthsTransactionsByWeek() {
      this.lastFiveMonthsTransactionsByWeekLoading = true;
      HTTP.get("dashboard/lastFiveMonthsTransactionsByWeek")
        .then((res) => {
          this.lastFiveMonthsTransactionsByWeek = res.data;
          this.lastFiveMonthsTransactionsByWeekLoading = false;
        })
        .catch((e) => {
          this.lastFiveMonthsTransactionsByWeekLoading = false;
          console.log(e);
        });
    },
    getLastMonthsTransactionsByDay() {
      this.lastMonthsTransactionsByDayLoading = true;
      HTTP.get("dashboard/lastMonthsTransactionsByDay")
        .then((res) => {
          this.lastMonthsTransactionsByDay = res.data;
          this.lastMonthsTransactionsByDayLoading = false;
        })
        .catch((e) => {
          this.lastMonthsTransactionsByDayLoading = false;
          console.log(e);
        });
    },
  },

  computed: {
    isLoading() {
      return this.$store.getters.getLoading;
    },
    dashboardData() {
      return this.$store.getters.getDashboardData;
    },
    typeSailpoints() {
      return this.$store.getters.getDashboardData.salepoints_types;
    },
  },
  mounted() {
    this.$store.dispatch("fetchDashboardDatafetchUsers");
    this.$store.dispatch("fetchwilayas");

    this.getLastMonthsTransactionsByDay();
    this.getLastFiveMonthsTransactions();
    this.getLastFiveMonthsTransactionsByWeek();
  },
};
</script>
  
  